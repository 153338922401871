import React from "react";

import Text from "../common/text";
import Header from "../common/header";
import Gutter from "../common/gutter";
import Body from "../common/body";

const Legal = ({ title, content }) => {
  return (
    <Body>
      <Header />
      <Gutter size={64} />
      <Text component="h2" font="title" size="xl">
        {title}
      </Text>
      <Text dangerouslySetInnerHTML={{ __html: content }} />
    </Body>
  );
};

Legal.propTypes = {};

export default Legal;
