import { graphql } from "gatsby";
import React from "react";
import { withPreview } from "gatsby-source-prismic";

import LegalComponent from "../components/legal";
import SEO from "../components/common/seo";

const CaseTemplate = ({ data }) => {
  const title = data.prismicLegal.data.title;
  const pageTitle = data.prismicLegal.data.page_title.text;
  const content = data.prismicLegal.data.content.html;
  return (
    <>
      <SEO title={pageTitle} />
      <LegalComponent title={title} content={content} />
    </>
  );
};

export default withPreview(CaseTemplate);

export const query = graphql`
  query LegalQuery {
    prismicLegal {
      uid
      data {
        title
        page_title {
          text
        }
        content {
          html
        }
      }
    }
  }
`;
